<template>
    <grid-view 
        title="Задания"
        filter
        create
        create-title="Новое задание"
        :url-create="createUrl"
        :url-update="updateUrl"
        store-module="task"
        :query-filters="['subject', 'grade']"
        :headers="headers"
        :format="formatItems"
        :data-table-options="{
            'show-select': true,
        }"
    >
        
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.year"
                        label="Календарный год" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.category"
                        required
                        :items="categories"
                        label="Категория"
                    />
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.collection"
                        label="Коллекция"
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.task"
                        label="Номер задания" 
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.option"
                        label="Вариант" 
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
        <!-- Extra buttons in title -->
        <template #[`title.right`]>
            <v-btn to="/task/scores-report" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-download
                </v-icon>
                <span>Выгрузка оценок</span>
            </v-btn>

            <!-- Delete selected items confirm dialog -->
            <v-btn to="/task/themes-upload" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-upload
                </v-icon>
                <span>Загрузить темы</span>
            </v-btn>

            <v-btn to="/task/export" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-download
                </v-icon>
                <span>Экспорт</span>
            </v-btn>
        </template>
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'

export default {
    name: 'TaskIndex',
    components: { GridView },
    data () {
        return {
            updateIndex: 0
        }
    },
    computed: {
        headers () {
            return [
                { text: 'ID', value: 'id', width: 1 },
                { text: 'Обновлен', value: 'updated_at', width: 1, sortable: false },
                { text: 'Метка загрузки', value: 'tag', width: 1, sortable: false },
                { text: 'Календарный год', value: 'year', width: 1, sortable: false },
                { text: 'Предмет', value: 'subject', width: 1, sortable: false },
                { text: 'Класс', value: 'grade', width: 1, sortable: false },
                { text: 'Вариант', value: 'option', width: 1, sortable: true },
                { text: 'Номер', value: 'task', width: 1, sortable: true },
                { text: 'Категория', value: 'category', width: 1 },
                { text: 'Коллекция', value: 'collection', width: 1 },
                // { text: 'Итоговая работа', value: 'is_final', width: 1, sortable: false },
                // { text: 'Название', value: 'name', width: 1 },
                // { text: 'Полное название', value: 'full_name', sortable: false },
                { text: '', value: 'edit', width: 1, sortable: false },
                { text: '', value: 'remove', width: 1, sortable: false },
                { text: '', value: 'create_similar', width: 1, sortable: false }
            ]
        },
        categories () {
            return [ {value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'} ]
        },
        createUrl () {
            this.updateIndex;
            return `/task/create${window.location.search}`
        },
        updateUrl () {
            this.updateIndex;
            return `/task/update/:id${window.location.search}`
        }
    },
    watch: {
        $route () {
            this.updateIndex++
        }
    },
    methods: {
        formatItems (items) {
            return _.map(items, (item) => {
                // item['is_final'] = item.is_final ? 'Да' : 'Нет';

                if (!item.tag)
                    item.tag = '-'

                // Category
                _.set(item, 'category', item.category === 'test' ? 'ВПР' : 'Контрольная работа');
                
                const dateUpdatedAt = new Date(item.updated_at * 1000);

                item['updated_at'] = _.join([
                    dateUpdatedAt.getDate(),
                    (dateUpdatedAt.getMonth() < 9 ? '0' : '') + (dateUpdatedAt.getMonth() + 1).toString(),
                    dateUpdatedAt.getFullYear()
                ], '.');

                return item;
            })
        } 
    }
}
</script>